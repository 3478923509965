.whiour_body {
    margin:auto;
    width: 700px;

}
.whiour_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.whiour_list {
    display: block;
    
        margin: 10px;
        padding: 20px;
        border: solid;
        border-width: 2px;
        border-color: black;
        border-radius: 30px;


}

.whiour_list_header {
    font-size: 24pt;
    font-weight: 700;
    margin-bottom: 20px;
}

.whiour_list_item {
    font-size: 16pt;
    display: flex;
    align-items: center;
}
@media(max-width: 1400px) {
    .whiour_body {
        margin: auto;
        width: 600px;
    
    }
}
@media(max-width: 1200px) {
    .whiour_body {
        margin: auto;
        width: 800px;

    }
}
@media(max-width: 1000px) {
    .whiour_body {
        margin: auto;
        width: 550px;

    }
}
@media(max-width: 800px) {
    .whiour_list_header {
        font-size: 18pt;
        font-weight: 700;
        margin-bottom: 20px;
    }
    
    .whiour_list_item {
        font-size: 12pt;
        display: flex;
        align-items: center;
    }
}
@media(max-width: 600px) {
    .whiour_body {
        margin: auto;
        width: 450px;

    }
}