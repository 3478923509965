.forminput_body{
    font-size: 16pt;    
    padding: 10px;
    width: 500px;
    margin:  auto;    
}
.forminput_input{
    width: 300px;
    border-style:solid;
    border-width: 3px;
    border-color: #000000;
}
.forminput_button{
    margin-top: 20px;
    border-style: solid;
    border-width: 3px;
    border-color: #000000;
    background: white;
}
.form_heder{
    font-size: 24pt;
}
.form_check{
    width: 12pt;
    height: 12pt;
}
.form_block_chek{
    margin-top: 20px;
    font-size: 12pt;
}
@media(max-width: 800px) {
    .forminput_body {
        font-size: 12pt;
        padding: 10px;
        width: 400px;
        margin: auto;
    }
    .form_heder {
        font-size: 18pt;
    }
    .form_check {
        width: 10pt;
        height: 10pt;
    }
    .form_block_chek {
        margin-top: 20px;
        font-size: 10pt;
    }
}