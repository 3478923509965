.whatsel_body {
    margin: auto;
    width: 700px;
}

.whatsel_row {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.whatsel_list {
    display: block;    
    margin: 10px;
    padding: 20px;
    border: solid;
    border-width: 2px;
    border-color: black;
    border-radius: 30px;
    background-color: white;

}

.whatsel_list_header {
    font-size: 24pt;
    font-weight: 700;
    margin-bottom: 20px;
}

.whatsel_list_item {
    font-size: 16pt;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.whatsel_point {
    width: 10px;
    height: 10px;
    margin-right: 20px;
}
@media(max-width: 1300px) {
    .whatsel_body {
        margin: auto;
        width: 600px;
    }
}
@media(max-width: 1200px) {
    .whatsel_body {
        margin: auto;
        width: 800px;
    }
}
@media(max-width: 1000px) {
    .whatsel_body {
        margin: auto;
        width: 600px;
    }
}
@media(max-width: 800px) {
    .whatsel_list_header {
        font-size: 18pt;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .whatsel_list_item {
        font-size: 12pt;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
}
@media(max-width: 600px) {
    .whatsel_body {
        margin: auto;
        width: 450px;
    }
}