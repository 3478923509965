.events_body{    
    display: flex;
    flex-direction: column;
    margin:auto;   
    max-width: 600px;
}
.events_imgs {
    max-width: 80px;
    border: solid;
    border-width: 2px;
    border-color: black;
    border-radius: 40px;
}
.events_heder{    
    text-align: center;
    font-size: 30pt;
    font-weight: 700;
    margin-bottom: 20px;
}
.events_list{
    display: flex;
    justify-content: space-between;        
    font-size: 16pt;
    text-align: center;  
     
}
@media(max-width: 1200px) {
    .events_imgs {
        max-width: 60px;
        border: solid;
        border-width: 2px;
        border-color: black;
        border-radius: 40px;
    }
}
@media(max-width: 800px) {
    .events_heder {
        text-align: center;
        font-size: 24pt;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .events_list {
        display: flex;
        justify-content: space-between;
        font-size: 12pt;
        text-align: center;    
    }
}
@media(max-width: 600px) {
    .events_heder {
            text-align: center;
            font-size: 20pt;
            font-weight: 700;
            margin-bottom: 20px;
        }
}