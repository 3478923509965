.about_body {
    margin: 30px;    
    color: aliceblue;
}

.about_img {
    width: 500px;
}

.about_row {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

}

.about_list {
    display: block;
    width: 1100px;
    height: auto;
    margin-left: 60px;


}

.about_list_header {
    font-size: 30pt;
    font-weight: 700;
    margin-bottom: 20px;
}

.about_list_item {
    font-size: 24pt;    
    display: flex;
    align-items: center;
}
@media(max-width: 1200px) {
    .about_list {
            display: block;
            width: 700px;
            height: auto;
            margin-left: 60px;
    
    
        }
}
@media(max-width: 800px) {
    .about_list {
        display: block;
        width: 500px;
        height: auto;
        margin-left: 30px;

    }
    .about_list_item {
        font-size: 18pt;
        display: flex;
        align-items: center;
    }
    .about_list_header {
        font-size: 24pt;
        font-weight: 700;
        margin-bottom: 20px;
    }
}
@media(max-width: 600px) {
    .about_list {
        display: block;
        width: 400px;
        height: auto;
        margin-left: 30px;    
    }
    .about_list_item {
        font-size: 16pt;
        display: flex;
        align-items: center;
    }
        
    .about_list_header {
        font-size: 20pt;
        font-weight: 700;
        margin-bottom: 20px;
    }
}
