.bottompanel_body{
    background-color: black;
    
}
.bottompanel_texts{
    color: aliceblue;
    margin-left: 100px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    
}
.bottompanel_text {
    margin-top: 10px;
    font-size: 16pt;
    width: 400px;
    margin-right: 100px;

}
@media(max-width: 1200px) {
    .bottompanel_text {
        margin-top: 10px;
        font-size: 16pt;
        width: 300px;
        margin-right: 100px;    
    }
}
@media(max-width: 1000px) {
    .bottompanel_text {
        margin-top: 10px;
        font-size: 16pt;
        width: 200px;
        margin-right: 100px;
    }
}
@media(max-width: 800px) {
    .bottompanel_text {
        margin-top: 10px;
        font-size: 12pt;
        width: 150px;
        margin-right: 50px;
    }
    .bottompanel_texts {
        color: aliceblue;
        margin-left: 50px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
    
    }
}