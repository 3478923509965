.ourprofil_body {    
    width: 700px;   
    margin: auto
}

.ourprofil_row {    
    display: flex;
    flex-direction: row;    
    justify-content: space-between;

}
.ourprofil_list {
    display: block;
    width: 700px;    
    padding: 20px;
    border: solid;
    border-width: 2px;
    border-color: black;
    border-radius: 30px;
    background-color: white;   
}
.ourprofil_list_header{
    font-size: 24pt;
    font-weight: 700;
    margin-bottom: 20px;
}
.ourprofil_list_item {
    font-size: 16pt;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.ourprofil_point {
    width: 10px;
    height: 10px;    
    margin-right: 20px;
}
@media(max-width: 1600px) {
    .ourprofil_body {
            margin: auto;
            width: 600px;
    
        }
}
@media(max-width: 1300px) {
    .ourprofil_body {
        margin: auto;
        width: 500px;

    }
}
@media(max-width: 1200px) {
    .ourprofil_body {
        margin: auto;
        width: 800px;

    }
        .ourprofil_list {
            display: block;
            width: 800px;
            padding: 20px;
            border: solid;
            border-width: 2px;
            border-color: black;
            border-radius: 30px;
            background-color: white;
        }
}
@media(max-width: 1000px) {
    .ourprofil_body {
        margin: auto;
        width: 550px;

    }

    .ourprofil_list {
        display: block;
        width: 550px;
        padding: 20px;
        border: solid;
        border-width: 2px;
        border-color: black;
        border-radius: 30px;
        background-color: white;
    }
}
@media(max-width: 800px) {
    .ourprofil_list_header {
        font-size: 18pt;
        font-weight: 700;
        margin-bottom: 20px;
    }
    
    .ourprofil_list_item {
        font-size: 12pt;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }
}
@media(max-width: 600px) {
    .ourprofil_body {
        margin: auto;
        width: 450px;    
    }
    
    .ourprofil_list {
        display: block;
        width: 450px;
        padding: 20px;
        border: solid;
        border-width: 2px;
        border-color: black;
        border-radius: 30px;
        background-color: white;
    }
}