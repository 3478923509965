.carousel_img_body{
       
    padding-left: 50px;
    padding-right: 50px;
    
    
}
.carousel_img_carousel{
    margin-top: 50px;
}
.carousel_img_block{
    display: flex;
    align-items: center;
}

.carousel_img_item{
    display: flex;
}
.carousel_img_header{
    font-size: 30pt;
    font-weight: 700;
    margin-bottom: 30px;
    text-align: center;
    padding: 50px;    
}
@media(max-width: 800px) {
    .carousel_img_header {
        font-size: 24pt;
        font-weight: 700;
        margin-bottom: 30px;
        text-align: center;
        padding: 50px;
    }
}