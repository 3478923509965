
.app_row0{
    display: flex;
    align-items: center;
    background-image: url("images/img13.jpg");
    background-size: cover;
    background-position: 40%;
}
.app_row1 {
    display: flex;
    align-items: center;
    padding-bottom: 50px;
    padding-top: 50px;
    
    
}
.app_row2 {
    display: flex;
    align-items: center;
    padding-bottom: 50px;
    padding-top: 50px;
}

.app_block{
    display: block;
    align-items: center;    
    width: 50%;
}


.App{
    background-image: url("images/back.jpg");
    background-size: cover;
}
@media(max-width: 1200px) {    
    
    .app_row1 {
        display: flex;
        align-items: center;
        padding-bottom: 0px;
        padding-top: 0px;
        flex-direction: column;            
    }
    .app_row2 {
        display: flex;
        align-items: center;
        padding-bottom: 0px;
        padding-top: 0px;
        flex-direction: column-reverse;
    }
    
    .app_block {
        display: block;
        align-items: center; 
        margin-bottom: 50px;      
        width: 100%;
    }
}