.ourteam_body {
    margin-bottom: 0px;
}


.ourteam_image {
    width: 250px; 
    border-radius: 200px;
}
.ourteam_header {
    font-size: 36pt;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
}
.ourteam_text{
    font-family: fantasy;    
    font-size: 24pt;    
}

@media(max-width: 800px) {
    .ourteam_header {
        font-size: 27pt;
        font-weight: 700;
        margin-bottom: 20px;
        text-align: center;
    }
    
    .ourteam_text {
        font-family: fantasy;
        font-size: 18pt;
    }
    
    .ourteam_image {
        width: 200px;
        border-radius: 150px;
    }
}

