.header_body{
    margin-bottom: 0px;

}
.header_logo{
    width: 100px;
    margin-right: 30px;
}
.header_band_text{
    font-size: 24pt;
}
.header_text {
    font-size: 16pt;
}
@media(max-width: 800px) {
    .header_logo {
        width: 80px;
        margin-right: 30px;
    }
    .header_band_text {
        font-size: 18pt;
    }
    
    .header_text {
        font-size: 12pt;
    }
}
@media(max-width: 600px) {
    .header_logo {
        width: 60px;
        margin-right: 15px;
    }
    .header_band_text {
        font-size: 16pt;
    }
    
}